import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import('@views/Index.vue'),
    // 添加单独的路由守卫
    // beforeEnter: () => {
    // http.post('admin/auth_login')
    // },
    // redirect: '/index',
    // children: [
    //   {
    //     path: 'index',
    //     name: 'index',
    //     component: () => import('@views/Index.vue'),
    //   },
    // ],
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@views/SearchResult.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@views/HomePage.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@views/Login.vue'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@views/Test.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@views/AboutMe.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: '/',
  },
  {
    path: '/fail',
    name: 'fail',
    component: () => import('@cp/Faile/index.vue')
  },
  {
    path: '/addPlane',
    name: 'addPlane',
    component: () => import('@cp/homePage/AddPlanPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
