<template>
  <div class="con" v-if="boxFlag" :class="{ show: showFlag }">{{ word }}</div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { toastGet, ToastObj } from '@plugins/toast'

const word = ref('')
const boxFlag = ref(false)
const showFlag = ref(false)

toastGet().subscribe((toastObj: ToastObj) => {
  word.value = toastObj.word
  showFunc()
  setTimeout(
    () => {
      hideFunc()
    },
    toastObj.duration ? toastObj.duration * 1000 : 1000
  )
})

function showFunc() {
  boxFlag.value = true
  setTimeout(() => {
    showFlag.value = true
  })
}

function hideFunc() {
  showFlag.value = false
  setTimeout(() => {
    boxFlag.value = false
    word.value = ''
  }, 1000)
}
</script>

<style scoped lang="scss">
@import '@/size.scss';
@import '@/font-face.scss';
.con {
  background: #000000;
  padding: px2rem(16) px2rem(35);
  max-width: px2rem(430);
  position: fixed;
  top: 40%;
  left: 50%;
  color: #bebebe;
  transform: translateX(-50%);
  border-radius: px2rem(54);
  font-family: AlibabaPuHuiTi75;
  font-weight: 600;
  font-size: px2rem(40);
  opacity: 0;
  transition: 0.4s;
  z-index: 200;
  white-space: normal;
}
.show {
  opacity: 1;
}
</style>
